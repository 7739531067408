var coreApp = angular.module('core', []);
function CategoryAutocompleteControllerBase($scope, CategoryAutocompleteService, listAdditionalParams) {
  $scope.getCategories = function (keywords) {
    var params = [keywords].concat(listAdditionalParams);
    return CategoryAutocompleteService.list.apply(CategoryAutocompleteService, params).then(function (results) {
      var categories = [];
      angular.forEach(results, function (category) {
        categories.push(category);
      });
      return categories;
    });
  };
  $scope.onCategorySelected = function ($item) {
    $scope.selectedCategory = $item;
  };
  $scope.hasSelectedCategory = function () {
    return _.isObject($scope.selectedCategory) && _.has($scope.selectedCategory, 'productCodeId');
  };
  (function init() {
    $scope.selectedProduct = undefined;
    listAdditionalParams = _.isUndefined(listAdditionalParams) ? [] : listAdditionalParams;
  }());
}
function CountryAutocompleteControllerBase($scope, CountryAutocompleteService, listAdditionalParams) {
  $scope.getCountries = function (keywords) {
    var params = [keywords].concat(listAdditionalParams);
    return CountryAutocompleteService.list.apply(CountryAutocompleteService, params).then(function (results) {
      var countries = [];
      angular.forEach(results, function (country) {
        countries.push(country);
      });
      return countries;
    });
  };
  $scope.onCountrySelected = function ($item) {
    $scope.selectedCountry = $item;
  };
  $scope.hasSelectedCountry = function () {
    return _.isObject($scope.selectedCountry) && _.has($scope.selectedCountry, 'name');
  };
  (function init() {
    $scope.selectedCountry = undefined;
    listAdditionalParams = _.isUndefined(listAdditionalParams) ? [] : listAdditionalParams;
  }());
}
function ProductAutocompleteControllerBase($scope, ProductAutocompleteService, listAdditionalParams) {
  $scope.getProducts = function (keywords) {
    var params = [keywords].concat(listAdditionalParams);
    return ProductAutocompleteService.list.apply(ProductAutocompleteService, params).then(function (results) {
      var products = [];
      angular.forEach(results, function (product) {
        products.push(product);
      });
      return products;
    });
  };
  $scope.onProductSelected = function ($item) {
    $scope.selectedProduct = $item;
    $scope.availableUoms = $scope.selectedProduct.uoms;
    $scope.selectedUom = $scope.availableUoms.length > 0 ? $scope.availableUoms[0] : undefined;
  };
  $scope.hasSelectedProduct = function () {
    return _.isObject($scope.selectedProduct) && _.has($scope.selectedProduct, 'partNum');
  };
  $scope.hasSelectedUom = function () {
    return $scope.hasSelectedProduct() && _.isObject($scope.selectedUom) && _.has($scope.selectedUom, 'uom');
  };
  (function init() {
    $scope.selectedProduct = undefined;
    $scope.selectedUom = undefined;
    listAdditionalParams = _.isUndefined(listAdditionalParams) ? [] : listAdditionalParams;
  }());
}
function SearchControllerBase($scope, $injector, service, defaultSearchParams, results, config) {
  config = config || {};
  var $timeout = $injector.get('$timeout');
  var SearchParams = $injector.get('SearchParams');
  var currentTimeout = null;
  $scope.isItemsEmpty = function () {
    return $scope.results.count === 0;
  };
  $scope.delete = function (model) {
    service.delete(model).then(function () {
      $scope.runSearch();
    });
  };
  $scope.pageXOfYDisplayText = function () {
    return 'Page ' + $scope.currentPage + ' of ' + $scope.numPages;
  };
  $scope.sortChanged = function (field) {
    $scope.searchParams.sortBy = field;
    $scope.searchParams.sortByDir = $scope.searchParams.sortByDir === 'ASC' ? 'DESC' : 'ASC';
    updateUrlSearchParams();
  };
  $scope.pageChanged = function () {
    $scope.searchParams.offset = $scope.currentPage * $scope.searchParams.limit - $scope.searchParams.limit;
    updateUrlSearchParams();
  };
  $scope.filterChanged = function () {
    filterChangedImpl(0);
  };
  $scope.textFilterChanged = function () {
    filterChangedImpl(500);
  };
  var filterChangedImpl = function (delay) {
    $scope.searchParams.offset = 0;
    $scope.currentPage = 1;
    if (currentTimeout) {
      $timeout.cancel(currentTimeout);
    }
    currentTimeout = $timeout(function () {
      updateUrlSearchParams();
    }, delay);
  };
  var updateUrlSearchParams = function () {
    if (config.updateUrl !== false) {
      if (angular.equals($scope.searchParams, defaultSearchParams)) {
        SearchParams.setParams({});
      } else {
        SearchParams.setParams($scope.searchParams);
      }
    } else {
      $scope.runSearch();
    }
  };
  $scope.runSearch = function () {
    service.search($scope.searchParams).then(function (results) {
      $scope.results = results;
      if (_.isFunction($scope.onSearchComplete)) {
        $scope.onSearchComplete();
      }
    });
  };
  var navigateTo = function (path) {
    $injector.get('Navigator').goTo(path);
  };
  var setCurrentPage = function () {
    $scope.currentPage = $scope.searchParams.offset / $scope.searchParams.limit + 1;
  };
  var addNewButton = function (url) {
    window.actionBar.addNewItem({
      clickEvent: function () {
        navigateTo(url);
      }
    });
  };
  var setupConfig = function () {
    var defaultConfig = { includeNewButton: true };
    config = _.defaults(config, defaultConfig);
  };
  (function init() {
    setupConfig();
    if (!_.isUndefined(window.actionBar)) {
      window.actionBar.reset();
      window.actionBar.addUndoItem({
        label: 'Reset Listing',
        clickEvent: function () {
          $scope.searchParams = _.clone(defaultSearchParams);
          updateUrlSearchParams();
          $scope.runSearch();
        }
      });
      if (config.includeNewButton === true) {
        var baseUrl = service.baseUrl || '';
        var newUrl = baseUrl === '' ? '/new' : '/' + baseUrl + '/new';
        addNewButton(newUrl);
      }
    }
    $scope.service = service;
    $scope.searchParams = SearchParams.hasParams() ? SearchParams.getParams() : angular.copy(defaultSearchParams);
    $scope.results = results;
    setCurrentPage();
    $scope.$watch(function () {
      return SearchParams.getParams();
    }, function (newValue, oldValue) {
      if (!angular.equals(newValue, oldValue)) {
        $scope.searchParams = _.defaults(SearchParams.getParams(), defaultSearchParams);
        setCurrentPage();
        $scope.runSearch();
      }
    }, true);
  }());
}
function UserAutocompleteControllerBase($scope, UserAutocompleteService, listAdditionalParams) {
  $scope.onSelectedUser = function (user) {
    $scope.selectedUser = user;
  };
  $scope.hasSelectedUser = function () {
    return _.isObject($scope.selectedUser) && _.has($scope.selectedUser, 'id');
  };
  (function init() {
    $scope.selectedUser = undefined;
    listAdditionalParams = _.isUndefined(listAdditionalParams) ? [] : listAdditionalParams;
  }());
}
angular.module('core').directive('addButton', function () {
  return {
    restrict: 'E',
    replace: true,
    transclude: true,
    scope: { size: '@' },
    template: '<button type=\'button\' class=\'btn btn-default btn-{{size}}\'><span ng-transclude></span> <i class=\'fa fa-plus\'></i></button>',
    link: function (scope, element, attrs) {
      if (!attrs.size) {
        attrs.size = 'sm';
      }
    }
  };
});
angular.module('core').directive('addressFormControls', function () {
  return {
    restrict: 'E',
    scope: { model: '=' },
    template: function () {
      var template = '<form-group label-text=\'First Name\'>';
      template += '<input ng-model=\'model.firstName\' type=\'text\' class=\'form-control\' />';
      template += '</form-group>';
      template += '<form-group label-text=\'Last Name\'>';
      template += '<input ng-model=\'model.lastName\' type=\'text\' class=\'form-control\' />';
      template += '</form-group>';
      template += '<form-group label-text=\'Company\'>';
      template += '<input ng-model=\'model.company\' type=\'text\' class=\'form-control\' />';
      template += '</form-group>';
      template += '<form-group label-text=\'Line 1\'>';
      template += '<input ng-model=\'model.line1\' type=\'text\' class=\'form-control\' />';
      template += '</form-group>';
      template += '<form-group label-text=\'Line 2\'>';
      template += '<input ng-model=\'model.line2\' type=\'text\' class=\'form-control\' />';
      template += '</form-group>';
      template += '<form-group label-text=\'Line 3\'>';
      template += '<input ng-model=\'model.line3\' type=\'text\' class=\'form-control\' />';
      template += '</form-group>';
      template += '<form-group label-text=\'Line 4\'>';
      template += '<input ng-model=\'model.line4\' type=\'text\' class=\'form-control\' />';
      template += '</form-group>';
      template += '<form-group label-text=\'City\'>';
      template += '<input ng-model=\'model.city\' type=\'text\' class=\'form-control\' />';
      template += '</form-group>';
      template += '<country-region-dropdowns model=\'model\'></country-region-dropdowns>';
      template += '<form-group label-text=\'Postal/Zip Code\'>';
      template += '<input ng-model=\'model.postalCode\' type=\'text\' class=\'form-control\' />';
      template += '</form-group>';
      template += '<form-group label-text=\'Phone\'>';
      template += '<input ng-model=\'model.phone\' type=\'text\' class=\'form-control\' />';
      template += '</form-group>';
      template += '<form-group label-text=\'Ext\'>';
      template += '<input ng-model=\'model.ext\' type=\'text\' class=\'form-control\' />';
      template += '</form-group>';
      template += '<form-group label-text=\'Email\'>';
      template += '<input ng-model=\'model.email\' type=\'text\' class=\'form-control\' />';
      template += '</form-group>';
      return template;
    }
  };
});
angular.module('core').directive('booleanFilterDropdown', function () {
  return {
    restrict: 'E',
    replace: true,
    scope: {},
    template: '<select ng-options=\'a.value as a.name for a in activeValues\' class=\'form-control\'></select>',
    link: function (scope) {
      scope.activeValues = [
        {
          name: '',
          value: ''
        },
        {
          name: 'Yes',
          value: '1'
        },
        {
          name: 'No',
          value: '0'
        }
      ];
    }
  };
});
angular.module('core').directive('categoryAutocomplete', function () {
  return {
    restrict: 'E',
    replace: true,
    template: function () {
      var temp = '<div>';
      temp += '<input type=\'text\' ng-model=\'selectedCategory\' typeahead=\'category.name for category in getCategories($viewValue)\' typeahead-on-select=\'onCategorySelected($item)\' typeahead-wait-ms=\'500\' class=\'form-control\' placeholder=\'Search categories\'>';
      temp += '</div>';
      return temp;
    }
  };
});
angular.module('core').directive('ckEditor', [
  'adminRootUrl',
  function (adminRootUrl) {
    var CKEDITOR_BASEPATH = adminRootUrl + 'application/modules/core/Administration/Assets/Js/ckeditor/';
    return {
      require: '?ngModel',
      link: function (scope, elm, attr, ngModel) {
        var config = { removeButtons: 'Source,Save,Styles,Anchor,Templates,NewPage,Preview,Print,Templates,document,Document,DocProps' };
        var editor = CKEDITOR.replace(elm[0], config);
        editor.on('pasteState', function () {
          scope.$apply(function () {
            ngModel.$setViewValue(editor.getData());
          });
        });
        ngModel.$render = function () {
          editor.setData(ngModel.$modelValue);
        };
      }
    };
  }
]);
angular.module('core').directive('confirmDirtyFormExit', function () {
  return {
    link: function (scope, elem) {
      var formName = elem.attr('name');
      var message = 'You have unsaved changed. Are you sure you want to navigate away from this page?';
      window.onbeforeunload = function () {
        if (scope[formName].$dirty) {
          return message;
        }
      };
      scope.$on('$locationChangeStart', function (event) {
        if (scope[formName].$dirty) {
          if (confirm(message)) {
            scope[formName].$dirty = false;
          } else {
            event.preventDefault();
          }
        }
      });
    }
  };
});
angular.module('core').directive('controlLabel', function () {
  return {
    restrict: 'E',
    replace: true,
    transclude: true,
    scope: {
      width: '@',
      text: '@',
      for: '@',
      helpText: '@'
    },
    require: '?^formGroup',
    template: function (element, attrs) {
      var temp = '<label for=\'{{for}}\' class=\'col-sm-{{width}} control-label\'><span ng-transclude></span>';
      if (attrs.helpText) {
        temp += ' <help-popover text=\'{{helpText}}\'></help-popover>';
      }
      temp += '</label>';
      return temp;
    },
    link: function (scope, element, attrs, formGroup) {
      if (!attrs.width) {
        attrs.width = formGroup ? formGroup.scope.labelWidth : 3;
      }
    }
  };
});
angular.module('core').directive('countryAutocomplete', function () {
  return {
    restrict: 'E',
    replace: true,
    template: function () {
      var temp = '<div>';
      temp += '<input type=\'text\' ng-model=\'selectedCountry\' typeahead=\'country.name for country in getCountries($viewValue)\' typeahead-on-select=\'onCountrySelected($item)\' typeahead-wait-ms=\'500\' class=\'form-control\' placeholder=\'Search countries\'>';
      temp += '</div>';
      return temp;
    }
  };
});
angular.module('core').directive('countryRegionDropdowns', function () {
  return {
    restrict: 'E',
    scope: { model: '=' },
    controller: [
      '$scope',
      'CountryService',
      'CountryRegionService',
      function ($scope, CountryService, CountryRegionService) {
        $scope.onCountryChange = function () {
          $scope.model.regionId = null;
          getRegions();
        };
        var getCountries = function () {
          CountryService.list().then(function (countries) {
            $scope.countries = countries;
          });
        };
        var getRegions = function () {
          CountryRegionService.getInstance($scope.model.countryId).list().then(function (regions) {
            $scope.regions = regions;
          });
        };
        (function init() {
          getCountries();
          getRegions();
        }());
      }
    ],
    template: function () {
      var template = '<form-group label-text=\'Country\'>';
      template += '<select ng-change=\'onCountryChange()\' ng-model=\'model.countryId\' ng-options=\'c.id as c.name for c in countries\' class=\'form-control\'>';
      template += '</select>';
      template += '</form-group>';
      template += '<form-group label-text=\'Region\'>';
      template += '<select ng-model=\'model.regionId\' ng-options=\'r.id as r.name for r in regions\' class=\'form-control\'>';
      template += '</select>';
      template += '</form-group>';
      return template;
    }
  };
});
angular.module('core').directive('datepickerButtonActionsContainer', function () {
  return {
    restrict: 'E',
    replace: true,
    transclude: true,
    scope: { dateProperty: '=' },
    controller: [
      '$scope',
      function ($scope) {
        $scope.clear = function () {
          $scope.dateProperty = null;
        };
      }
    ],
    template: function () {
      temp = '<div class=\'row\'>';
      temp += '<div class=\'col-md-10\' ng-transclude>';
      temp += '</div>';
      temp += '<div class=\'col-md-2\'>';
      temp += '<button type=\'button\' class=\'btn btn-sm btn-danger pull-left\' ng-click=\'clear()\'>Clear</button>';
      temp += '</div>';
      temp += '</div>';
      return temp;
    }
  };
});
angular.module('core').directive('deleteButton', function () {
  return {
    restrict: 'E',
    transclude: true,
    scope: {
      size: '@',
      message: '@',
      action: '&'
    },
    template: '<button ng-click=\'deleteInvoked()\' type=\'button\' class=\'btn btn-danger btn-{{size}}\'><span ng-transclude></span> <i class=\'fa fa-times\'></i></button>',
    controller: [
      '$scope',
      function ($scope) {
        $scope.deleteInvoked = function (itemIndex) {
          admin.confirm($scope.message, function () {
            $scope.action(itemIndex);
          });
        };
      }
    ],
    link: function (scope, element, attrs) {
      if (!attrs.size) {
        attrs.size = 'sm';
      }
      if (attrs.text) {
        attrs.text += ' ';
      } else {
        attrs.text = '';
      }
      if (!attrs.message) {
        attrs.message = 'Are you sure you want to delete this item?';
      }
    }
  };
});
angular.module('core').directive('fieldValidations', [
  '$compile',
  function ($compile) {
    return {
      priority: 1001,
      terminal: true,
      require: '^formGroup',
      link: function (scope, element, attrs, formGroup) {
        element.attr('name', formGroup.scope.fieldName);
        var validations = scope.$eval(attrs.fieldValidations);
        if (validations !== undefined) {
          if (validations.type !== undefined) {
            element.attr('type', validations.type);
          }
          if (validations.required !== undefined) {
            element.attr('ng-required', validations.required);
          }
          if (validations.min !== undefined) {
            element.attr('min', validations.min);
          }
          if (validations.max !== undefined) {
            element.attr('max', validations.max);
          }
          if (validations.minlength !== undefined) {
            element.attr('ng-minlength', validations.minlength);
          }
          if (validations.maxlength !== undefined) {
            element.attr('ng-maxlength', validations.maxlength);
          }
          if (validations.pattern !== undefined) {
            element.attr('ng-pattern', '/^' + validations.pattern + '$/');
          }
        }
        element.removeAttr('field-validations');
        $compile(element)(scope);
      }
    };
  }
]);
angular.module('core').directive('formGroup', function () {
  return {
    restrict: 'E',
    transclude: true,
    scope: {
      fieldWidth: '@',
      labelWidth: '@',
      labelText: '@',
      helpText: '@',
      validations: '@',
      fieldName: '@',
      validationErrorMessage: '@'
    },
    require: '^horizontalForm',
    controller: [
      '$scope',
      '$element',
      '$attrs',
      function ($scope, $element, $attrs) {
        this.scope = $scope;
      }
    ],
    template: function (element, attrs) {
      var temp = '<div class=\'form-group\'>';
      if (!_.isUndefined(attrs.labelText)) {
        temp += '<control-label width=\'{{labelWidth}}\' help-text=\'{{helpText}}\'>{{labelText}}</control-label>';
      }
      temp += '<div class=\'col-sm-{{fieldWidth}}\' ng-transclude></div>';
      temp += '<div class=\'col-sm-1 form-control-static\'>';
      temp += '<i class=\'fa fa-warning ng-invalid\' ng-show=\'show\' tooltip=\'{{validationErrorMessage}}\'></i>';
      temp += '</div>';
      temp += '</div>';
      return temp;
    },
    link: function (scope, element, attrs, form) {
      if (!attrs.labelWidth) {
        attrs.labelWidth = form ? form.labelWidth : 3;
      }
      if (!attrs.fieldWidth) {
        attrs.fieldWidth = form ? form.fieldWidth : 4;
      }
      form.scope.$watch(form.name + '.' + attrs.fieldName + '.$invalid', function (invalid) {
        scope.show = invalid;
      });
    }
  };
});
angular.module('core').directive('gridDeleteButton', function () {
  return {
    restrict: 'E',
    template: function (element, attrs) {
      return '<delete-button size=\'xs\' action=\'' + attrs.action + '\'></delete-button>';
    }
  };
});
angular.module('core').directive('gridEditLink', function () {
  return {
    restrict: 'E',
    template: function (element, attrs) {
      var tooltip = 'Edit this item';
      if (attrs.tooltip !== undefined) {
        tooltip = attrs.tooltip;
      }
      return '<a href=\'' + attrs.href + '\' tooltip=\'' + tooltip + '\'><i class=\'fa fa-pencil\'></i></a>';
    }
  };
});
angular.module('core').directive('gridFilterColumn', function () {
  return {
    restrict: 'A',
    template: function (element, attrs) {
      var fullParamName = attrs.paramName + 'Filter';
      var standardAttributes = 'ng-model=\'searchParams.' + fullParamName + '\' class=\'form-control\' id=\'' + fullParamName + '\'';
      var temp = '<label class=\'sr-only\' for=\'' + fullParamName + '\'>Filter by ' + attrs.paramName + '</label>';
      if (attrs.type && attrs.type === 'bool') {
        temp += '<boolean-filter-dropdown ng-change=\'filterChanged()\' ' + standardAttributes + '></boolean-filter-dropdown>';
      } else if (attrs.type && attrs.type === 'dropdown') {
        temp += '<select ng-change=\'filterChanged()\' ng-options=\'' + attrs.options + '\' ' + standardAttributes + '>';
        temp += '<option value=\'\'></option>';
        temp += ' </select>';
      } else {
        var placeholder = attrs.placeholder ? attrs.placeholder : '';
        temp += '<input ng-change=\'textFilterChanged()\' type=\'search\' ' + standardAttributes + ' placeholder=\'' + placeholder + '\' />';
      }
      return temp;
    }
  };
});
angular.module('core').directive('gridNoItemsAlert', function () {
  return {
    restrict: 'E',
    template: function (element, attrs) {
      var message = 'No items found';
      if (attrs) {
        if (attrs.message) {
          message = attrs.message;
        }
      }
      var temp = '<div ng-show=\'isItemsEmpty()\' class=\'alert alert-info\'>';
      temp += message;
      temp += '</div>';
      return temp;
    }
  };
});
angular.module('core').directive('gridPagination', function () {
  return {
    restrict: 'E',
    template: function (element, params) {
      var temp = '<div ng-show=\'!isItemsEmpty()\' class=\'text-center col-md-12 small\'>';
      temp += '<pagination ng-model=\'currentPage\' ng-change=\'pageChanged()\' boundary-links=\'true\' total-items=\'results.count\' items-per-page=\'searchParams.limit\' max-size=\'5\' num-pages=\'numPages\' class=\'pagination\'></pagination>';
      temp += '<br/>';
      temp += '<span ng-bind=\'pageXOfYDisplayText()\' class=\'label label-default\'></span>';
      temp += '</div>';
      var nomsg = '';
      if (params.noItemsMessage) {
        nomsg = ' message=\'' + params.noItemsMessage + '\' ';
      }
      temp += '<grid-no-items-alert' + nomsg + '></grid-no-items-alert>';
      return temp;
    }
  };
});
angular.module('core').directive('gridSortColumn', function () {
  return {
    restrict: 'A',
    template: function (element, attrs) {
      var temp = '<a href=\'\' ng-click="sortChanged(\'' + attrs.paramName + '\')">';
      temp += attrs.label;
      temp += '<span ng-show="searchParams.sortBy == \'' + attrs.paramName + '\'">';
      temp += '<i ng-class="{true: \'fa fa-chevron-up\', false: \'fa fa-chevron-down\'}[searchParams.sortByDir == \'ASC\']"></i>';
      temp += '</span>';
      temp += '</a>';
      return temp;
    }
  };
});
angular.module('core').directive('gridViewLink', function () {
  return {
    restrict: 'E',
    template: function (element, attrs) {
      var tooltip = 'Edit this item';
      if (attrs.tooltip !== undefined) {
        tooltip = attrs.tooltip;
      }
      return '<a href=\'' + attrs.href + '\' tooltip=\'' + tooltip + '\'><i class=\'fa fa-eye\'></i></a>';
    }
  };
});
angular.module('core').directive('helpPopover', function () {
  return {
    restrict: 'E',
    scope: { text: '@' },
    template: '<i class=\'fa fa-question-circle\' popover=\'{{text}}\' ng-show=\'text !== ' + '""' + '\' popover-trigger=\'mouseenter\'></i>'
  };
});
angular.module('core').directive('horizontalForm', function () {
  return {
    restrict: 'E',
    replace: true,
    transclude: true,
    scope: false,
    template: '<form class=\'form-horizontal\' name=\'settingsForm\' confirm-dirty-form-exit ng-transclude></form>',
    controller: [
      '$scope',
      '$element',
      '$attrs',
      function ($scope, $element, $attrs) {
        this.scope = $scope;
        this.name = $attrs.name;
        this.labelWidth = $attrs.labelWidth ? $attrs.labelWidth : 3;
        this.fieldWidth = $attrs.fieldWidth ? $attrs.fieldWidth : 4;
      }
    ]
  };
});
angular.module('core').directive('productAutocomplete', function () {
  return {
    restrict: 'E',
    replace: true,
    template: function () {
      var temp = '<div>';
      temp += '<input type=\'text\' ng-model=\'selectedProduct\' typeahead=\'product.partNum for product in getProducts($viewValue)\' typeahead-on-select=\'onProductSelected($item)\' typeahead-wait-ms=\'500\' class=\'form-control\' placeholder=\'Search products\'>';
      temp += '</div>';
      return temp;
    }
  };
});
angular.module('core').directive('productUomSelect', function () {
  return {
    restrict: 'E',
    replace: true,
    template: function () {
      var temp = '<div>';
      temp += '<select ng-model=\'selectedUom\' ng-options=\'u.uom for u in availableUoms\' class=\'form-control\' ng-disabled=\'!hasSelectedProduct()\'></select>';
      temp += '</div>';
      return temp;
    }
  };
});
angular.module('core').directive('responsiveGrid', function () {
  return {
    restrict: 'A',
    link: function (scope, element) {
      element.addClass('table table-striped table-hover small');
      element.wrap('<div class=\'table-responsive\'></div>');
    }
  };
});
angular.module('core').value('uiSortableConfig', {}).directive('uiSortable', [
  'uiSortableConfig',
  '$timeout',
  '$log',
  function (uiSortableConfig, $timeout, $log) {
    return {
      require: '?ngModel',
      link: function (scope, element, attrs, ngModel) {
        var savedNodes;
        function combineCallbacks(first, second) {
          if (second && typeof second === 'function') {
            return function (e, ui) {
              first(e, ui);
              second(e, ui);
            };
          }
          return first;
        }
        function hasSortingHelper(element, ui) {
          var helperOption = element.sortable('option', 'helper');
          return helperOption === 'clone' || typeof helperOption === 'function' && ui.item.sortable.isCustomHelperUsed();
        }
        var opts = {};
        var callbacks = {
            receive: null,
            remove: null,
            start: null,
            stop: null,
            update: null
          };
        var wrappers = { helper: null };
        angular.extend(opts, uiSortableConfig, scope.$eval(attrs.uiSortable));
        if (!angular.element.fn || !angular.element.fn.jquery) {
          $log.error('ui.sortable: jQuery should be included before AngularJS!');
          return;
        }
        if (ngModel) {
          // When we add or remove elements, we need the sortable to 'refresh'
          // so it can find the new/removed elements.
          scope.$watch(attrs.ngModel + '.length', function () {
            // Timeout to let ng-repeat modify the DOM
            $timeout(function () {
              // ensure that the jquery-ui-sortable widget instance
              // is still bound to the directive's element
              if (!!element.data('ui-sortable')) {
                element.sortable('refresh');
              }
            });
          });
          callbacks.start = function (e, ui) {
            // Save the starting position of dragged item
            ui.item.sortable = {
              index: ui.item.index(),
              cancel: function () {
                ui.item.sortable._isCanceled = true;
              },
              isCanceled: function () {
                return ui.item.sortable._isCanceled;
              },
              isCustomHelperUsed: function () {
                return !!ui.item.sortable._isCustomHelperUsed;
              },
              _isCanceled: false,
              _isCustomHelperUsed: ui.item.sortable._isCustomHelperUsed
            };
          };
          callbacks.activate = function () {
            // We need to make a copy of the current element's contents so
            // we can restore it after sortable has messed it up.
            // This is inside activate (instead of start) in order to save
            // both lists when dragging between connected lists.
            savedNodes = element.contents();
            // If this list has a placeholder (the connected lists won't),
            // don't inlcude it in saved nodes.
            var placeholder = element.sortable('option', 'placeholder');
            // placeholder.element will be a function if the placeholder, has
            // been created (placeholder will be an object).  If it hasn't
            // been created, either placeholder will be false if no
            // placeholder class was given or placeholder.element will be
            // undefined if a class was given (placeholder will be a string)
            if (placeholder && placeholder.element && typeof placeholder.element === 'function') {
              var phElement = placeholder.element();
              // workaround for jquery ui 1.9.x,
              // not returning jquery collection
              phElement = angular.element(phElement);
              // exact match with the placeholder's class attribute to handle
              // the case that multiple connected sortables exist and
              // the placehoilder option equals the class of sortable items
              var excludes = element.find('[class=\'' + phElement.attr('class') + '\']');
              savedNodes = savedNodes.not(excludes);
            }
          };
          callbacks.update = function (e, ui) {
            // Save current drop position but only if this is not a second
            // update that happens when moving between lists because then
            // the value will be overwritten with the old value
            if (!ui.item.sortable.received) {
              ui.item.sortable.dropindex = ui.item.index();
              ui.item.sortable.droptarget = ui.item.parent();
              // Cancel the sort (let ng-repeat do the sort for us)
              // Don't cancel if this is the received list because it has
              // already been canceled in the other list, and trying to cancel
              // here will mess up the DOM.
              element.sortable('cancel');
            }
            // Put the nodes back exactly the way they started (this is very
            // important because ng-repeat uses comment elements to delineate
            // the start and stop of repeat sections and sortable doesn't
            // respect their order (even if we cancel, the order of the
            // comments are still messed up).
            if (hasSortingHelper(element, ui) && !ui.item.sortable.received) {
              // restore all the savedNodes except .ui-sortable-helper element
              // (which is placed last). That way it will be garbage collected.
              savedNodes = savedNodes.not(savedNodes.last());
            }
            savedNodes.appendTo(element);
            // If received is true (an item was dropped in from another list)
            // then we add the new item to this list otherwise wait until the
            // stop event where we will know if it was a sort or item was
            // moved here from another list
            if (ui.item.sortable.received && !ui.item.sortable.isCanceled()) {
              scope.$apply(function () {
                ngModel.$modelValue.splice(ui.item.sortable.dropindex, 0, ui.item.sortable.moved);
              });
            }
          };
          callbacks.stop = function (e, ui) {
            // If the received flag hasn't be set on the item, this is a
            // normal sort, if dropindex is set, the item was moved, so move
            // the items in the list.
            if (!ui.item.sortable.received && 'dropindex' in ui.item.sortable && !ui.item.sortable.isCanceled()) {
              scope.$apply(function () {
                ngModel.$modelValue.splice(ui.item.sortable.dropindex, 0, ngModel.$modelValue.splice(ui.item.sortable.index, 1)[0]);
              });
            } else {
              // if the item was not moved, then restore the elements
              // so that the ngRepeat's comment are correct.
              if ((!('dropindex' in ui.item.sortable) || ui.item.sortable.isCanceled()) && !hasSortingHelper(element, ui)) {
                savedNodes.appendTo(element);
              }
            }
          };
          callbacks.receive = function (e, ui) {
            // An item was dropped here from another list, set a flag on the
            // item.
            ui.item.sortable.received = true;
          };
          callbacks.remove = function (e, ui) {
            // Workaround for a problem observed in nested connected lists.
            // There should be an 'update' event before 'remove' when moving
            // elements. If the event did not fire, cancel sorting.
            if (!('dropindex' in ui.item.sortable)) {
              element.sortable('cancel');
              ui.item.sortable.cancel();
            }
            // Remove the item from this list's model and copy data into item,
            // so the next list can retrive it
            if (!ui.item.sortable.isCanceled()) {
              scope.$apply(function () {
                ui.item.sortable.moved = ngModel.$modelValue.splice(ui.item.sortable.index, 1)[0];
              });
            }
          };
          wrappers.helper = function (inner) {
            if (inner && typeof inner === 'function') {
              return function (e, item) {
                var innerResult = inner(e, item);
                item.sortable._isCustomHelperUsed = item !== innerResult;
                return innerResult;
              };
            }
            return inner;
          };
          scope.$watch(attrs.uiSortable, function (newVal) {
            // ensure that the jquery-ui-sortable widget instance
            // is still bound to the directive's element
            if (!!element.data('ui-sortable')) {
              angular.forEach(newVal, function (value, key) {
                if (callbacks[key]) {
                  if (key === 'stop') {
                    // call apply after stop
                    value = combineCallbacks(value, function () {
                      scope.$apply();
                    });
                  }
                  // wrap the callback
                  value = combineCallbacks(callbacks[key], value);
                } else if (wrappers[key]) {
                  value = wrappers[key](value);
                }
                element.sortable('option', key, value);
              });
            }
          }, true);
          angular.forEach(callbacks, function (value, key) {
            opts[key] = combineCallbacks(value, opts[key]);
          });
        } else {
          $log.info('ui.sortable: ngModel not provided!', element);
        }
        element.sortable(opts);
      }
    };
  }
]);
angular.module('core').directive('userAutocomplete', [
  '$rootScope',
  function ($rootScope) {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        onSelectedUser: '&',
        additionalParameters: '=',
        placeholder: '@',
        selectedValueLabel: '@'
      },
      controller: [
        '$scope',
        'UserAutocompleteService',
        function ($scope, UserAutocompleteService) {
          $scope.getUsers = function (keywords) {
            var params = [keywords].concat($scope.additionalParameters);
            return UserAutocompleteService.list.apply(UserAutocompleteService, params).then(function (results) {
              var users = [];
              angular.forEach(results, function (user) {
                users.push(user);
              });
              return users;
            });
          };
          $scope.onUserSelected = function (item) {
            $scope.selectedUser = item;
            $scope.onSelectedUser({ user: item });
            $rootScope.$broadcast('event.autocomplete-user-selected', item);
          };
          (function init() {
            $scope.selectedUser = undefined;
          }());
        }
      ],
      template: function (elem, attrs) {
        var temp = '';
        if (attrs.selectedValueLabel) {
          temp += '<div class=\'input-group col-md-12\'>';
          temp += '<span class=\'input-group-addon\'>{{selectedValueLabel}}</span>';
        } else {
          temp += '<div>';
        }
        temp += '<input type=\'text\' ng-model=\'selectedUser\' typeahead=\'user.name for user in getUsers($viewValue)\' typeahead-on-select=\'onUserSelected($item)\' typeahead-wait-ms=\'500\' class=\'form-control\' placeholder=\'Search Users...\'>';
        temp += '</div>';
        return temp;
      }
    };
  }
]);
angular.module('core').filter('capitaliseAll', [
  'Inflector',
  function (Inflector) {
    return function (str) {
      return Inflector.capitaliseAll(str);
    };
  }
]);
angular.module('core').filter('dasherise', [
  'Inflector',
  function (Inflector) {
    return function (str) {
      return Inflector.dasherise(str);
    };
  }
]);
angular.module('core').filter('dateFormat', function () {
  return function (date, format) {
    if (_.isUndefined(format)) {
      format = 'lll';
    }
    return moment(date).format(format);
  };
});
angular.module('core').filter('encode', function () {
  return function (text) {
    return encodeURIComponent(text);
  };
});
angular.module('core').filter('humanize', function () {
  return function (str) {
    return str.replace(/_/g, ' ').replace(/(\w+)/g, function (match) {
      return match.charAt(0).toUpperCase() + match.slice(1);
    });
  };
});
angular.module('core').filter('truncate', function () {
  return function (text, length, end) {
    if (isNaN(length)) {
      length = 10;
    }
    if (end === undefined) {
      end = '...';
    }
    if (text.length <= length || text.length - end.length <= length) {
      return text;
    } else {
      return String(text).substring(0, length - end.length) + end;
    }
  };
});
// strip script/html tags from a string
angular.module('core').filter('stripHtmlTags', function () {
  return function (str) {
    var element = document.createElement('div');
    if (str && typeof str === 'string') {
      str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, '');
      str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, '');
      element.innerHTML = str;
      str = element.textContent;
      element.textContent = '';
    }
    return str;
  };
});
angular.module('core').factory('FieldValidationBuilder', function () {
  var getInstance = function () {
    var intPattern = '\\d+';
    var permalinkPattern = '[a-z0-9-]+';
    var validations = {
        required: true,
        type: 'text'
      };
    var setMessage = function () {
      messages = [];
      if (validations.type === 'text' && validations.required === true) {
        messages.push('Required');
        if (validations.pattern !== undefined && validations.pattern === permalinkPattern) {
          messages.push('Cannot have spaces or special characters other than a dash');
        }
      }
      if (validations.type !== 'text') {
        var typeMessage = validations.type;
        if (validations.type === 'number' && validations.pattern !== undefined && validations.pattern === intPattern) {
          typeMessage = 'integer';
        }
        messages.push('Expected ' + typeMessage + getNumberRangeMessage());
      }
      var lengthRange = getLengthRangeMessage();
      if (lengthRange) {
        messages.push(lengthRange);
      }
      validations.message = messages.join(', ');
    };
    var getNumberRangeMessage = function () {
      var numberRange = [];
      if (validations.min !== undefined) {
        numberRange.push('>= ' + validations.min);
      }
      if (validations.max !== undefined) {
        numberRange.push('<= ' + validations.max);
      }
      return numberRange.length ? ' ' + numberRange.join(' and ') : '';
    };
    var getLengthRangeMessage = function () {
      var lengthRange = [];
      if (validations.minlength !== undefined) {
        lengthRange.push('>= ' + validations.minlength);
      }
      if (validations.maxlength !== undefined) {
        lengthRange.push('<= ' + validations.maxlength);
      }
      return lengthRange.length ? 'length ' + lengthRange.join(' and ') : '';
    };
    return {
      isInteger: function () {
        return this.isNumber().pattern(intPattern);
      },
      isPermalink: function () {
        return this.pattern(permalinkPattern);
      },
      isNotRequired: function () {
        validations.required = false;
        return this;
      },
      isNumber: function () {
        return this.isType('number');
      },
      isEmail: function () {
        return this.isType('email');
      },
      isPassword: function () {
        return this.isType('password');
      },
      isDate: function () {
        return this.isDate('date');
      },
      isUrl: function () {
        return this.isType('url');
      },
      isType: function (type) {
        validations.type = type;
        return this;
      },
      min: function (min) {
        validations.min = min;
        return this;
      },
      max: function (max) {
        validations.max = max;
        return this;
      },
      minlength: function (minlength) {
        validations.minlength = minlength;
        return this;
      },
      maxlength: function (maxlength) {
        validations.maxlength = maxlength;
        return this;
      },
      pattern: function (pattern) {
        validations.pattern = pattern;
        return this;
      },
      message: function (message) {
        validations.message = message;
        return this;
      },
      get: function () {
        if (validations.message === undefined) {
          setMessage();
        }
        return validations;
      }
    };
  };
  return {
    getInstance: getInstance,
    requiredString: getInstance().get(),
    requiredSingleCharacter: getInstance().minlength(1).maxlength(1).message('Required single character').get(),
    requiredPassword: getInstance().isPassword().get(),
    requiredEmail: getInstance().isEmail().get(),
    requiredPermalink: getInstance().isPermalink().get(),
    requiredInteger: getInstance().isInteger().get(),
    requiredIntegerGreaterThanZero: getInstance().isInteger().min(1).get(),
    requiredPercentage: getInstance().isInteger().min(0).max(100).message('Percentage value between 0-100 expected').get(),
    requiredNumber: getInstance().isNumber().get(),
    requiredUrl: getInstance().isUrl().get()
  };
});
angular.module('core').factory('HttpService', [
  '$http',
  '$q',
  function ($http, $q) {
    var resolving = false;
    var get = function (config) {
      return buildRequestPromise(config);
    };
    var post = function (config) {
      config.method = 'POST';
      return buildRequestPromise(config);
    };
    var put = function (config) {
      config.method = 'PUT';
      return buildRequestPromise(config);
    };
    var doDelete = function (config) {
      config.method = 'DELETE';
      return buildRequestPromise(config);
    };
    var buildRequestPromise = function (config) {
      config = normaliseConfig(config);
      if (config.loader.show && !resolving) {
        if (window.Communications !== undefined) {
          if (Communications.getServiceType) {
            Communications[Communications.getServiceType(config.loader.type)](config.loader.message);
          }
        }
      }
      var deferred = $q.defer();
      $http(config).then(function (results) {
        deferred.resolve(results.data);
      }, function (err) {
        if (window.logger) {
          logger.notifyError(err.data);
        }
        // if (window.Communications !== undefined) {
        //     Communications.Notify.Error(err.data);
        //     Communications[Communications.getServiceType(config.loader.type)](false);
        // }
        deferred.reject(err.data);
      }).finally(function () {
        if (config.loader.show && !resolving) {
          if (window.Communications !== undefined) {
            Communications[Communications.getServiceType(config.loader.type)](false);
          }
        }
      });
      return deferred.promise;
    };
    var normaliseConfig = function (config) {
      var defaultConfig = {
          method: 'GET',
          url: '',
          cache: false,
          loader: {
            show: true,
            type: 'loading',
            message: 'Loading...'
          }
        };
      config.loader = _.defaults(config.loader || {}, defaultConfig.loader);
      return _.defaults(config, defaultConfig);
    };
    var setResolving = function (val) {
      resolving = val;
    };
    return {
      get: get,
      post: post,
      put: put,
      delete: doDelete,
      setResolving: setResolving
    };
  }
]);
angular.module('core').factory('Inflector', function () {
  var dasherise = function (str) {
    return S(str).slugify().s;
  };
  var capitaliseAll = function (str) {
    var tmp = S(str).humanize().s;
    return tmp.replace(/\w\S*/g, function (txt) {
      return S(txt).capitalize().s;
    });
  };
  return {
    dasherise: dasherise,
    capitaliseAll: capitaliseAll
  };
});
angular.module('core').factory('RestHttpService', [
  'HttpService',
  'rootUrl',
  function (HttpService, rootUrl) {
    var buildUrl = function (baseUrl, path) {
      return rootUrl + (baseUrl === '' ? path : baseUrl + '/' + path);
    };
    return {
      getInstance: function (baseUrl) {
        return {
          search: function (queryParams) {
            return HttpService.get({
              url: buildUrl(baseUrl, ''),
              params: queryParams,
              loader: {
                message: 'Searching...',
                type: 'cursor_follow'
              }
            });
          },
          list: function () {
            return HttpService.get({
              url: buildUrl(baseUrl, ''),
              loader: { show: false }
            });
          },
          get: function (id) {
            return HttpService.get({
              url: buildUrl(baseUrl, id),
              loader: {
                message: 'Loading Item...',
                type: 'cursor_follow'
              }
            });
          },
          new: function () {
            return HttpService.get({
              url: buildUrl(baseUrl, 'new'),
              loader: { show: false }
            });
          },
          update: function (model) {
            return HttpService.put({
              url: buildUrl(baseUrl, model.id),
              data: model,
              loader: { message: 'Updating Item...' }
            });
          },
          create: function (model) {
            return HttpService.post({
              url: buildUrl(baseUrl, ''),
              data: model,
              loader: { message: 'Creating Item...' }
            });
          },
          delete: function (id) {
            return HttpService.delete({
              url: buildUrl(baseUrl, id),
              loader: { message: 'Deleting Item...' }
            });
          }
        };
      }
    };
  }
]);
angular.module('core').provider('routing', [
  '$locationProvider',
  '$routeProvider',
  function ($locationProvider, $routeProvider) {
    var viewsLocation;
    return {
      configure: function (config) {
        viewsLocation = config.viewsLocation;
        $locationProvider.html5Mode(true);
        $routeProvider.otherwise(config.otherwise ? config.otherwise : '/');
      },
      when: function (url, route) {
        var template = route.template ? route.template : route.controller.name;
        $routeProvider.when(url, {
          reloadOnSearch: false,
          templateUrl: viewsLocation + '/' + template + '.html',
          controller: route.controller.name,
          resolve: route.controller.data
        });
        return this;
      },
      $get: function () {
        return {};
      }
    };
  }
]);
angular.module('core').factory('SearchParams', [
  '$location',
  function ($location) {
    var getParams = function () {
      var params = {};
      _.forEach($location.search(), function (value, key) {
        params[key] = value;
      });
      return params;
    };
    var setParams = function (params) {
      var temp = {};
      angular.forEach(params, function (value, key) {
        if (value !== '' && value !== null) {
          temp[key] = value;
        }
      });
      $location.search(temp);
    };
    var hasParams = function () {
      return !_.isEmpty($location.search());
    };
    return {
      getParams: getParams,
      setParams: setParams,
      hasParams: hasParams
    };
  }
]);
angular.module('core').service('SessionStorage', function () {
  this.put = function (key, value) {
    sessionStorage.setItem(key, JSON.stringify(value));
  };
  this.get = function (key) {
    return JSON.parse(sessionStorage.getItem(key));
  };
});
angular.module('core').factory('SettingsPreparer', function () {
  var groups;
  var findSetting = function (name, settings) {
    return _.find(settings, function (setting) {
      return setting.name === name;
    });
  };
  var prepareForView = function (settings, definitions) {
    var shouldBuildGroups = groups === undefined;
    groups = groups || [];
    _.forEach(definitions, function (definition) {
      var setting = findSetting(definition.name, settings);
      if (setting) {
        if (definition.validations && definition.validations.type && definition.validations.type === 'number') {
          setting.value = parseFloat(setting.value);
        }
        definition.value = setting.value;
        definition.valueLabel = setting.valueLabel;
        definition.visible = _.isUndefined(definition.visible) ? true : definition.visible;
        if (definition.group && shouldBuildGroups) {
          groups.push({ title: definition.group });
        }
      }
    });
    return {
      settings: definitions,
      groups: _.uniq(groups, function (group) {
        return group.title;
      })
    };
  };
  var prepareForSave = function (settings) {
    var temp = [];
    _.forEach(settings, function (setting) {
      temp.push({
        name: setting.name,
        value: setting.value
      });
    });
    return temp;
  };
  return {
    findSetting: findSetting,
    prepareForView: prepareForView,
    prepareForSave: prepareForSave
  };
});
angular.module('core').factory('UrlService', [
  '$location',
  function ($location) {
    var getBaseUrl = function () {
      return $location.protocol() + '://' + $location.host() + '/';
    };
    var getCurrentParentUrl = function () {
      return $location.absUrl().substr(0, $location.absUrl().lastIndexOf('/'));
    };
    return {
      getBaseUrl: getBaseUrl,
      getCurrentParentUrl: getCurrentParentUrl
    };
  }
]);